//scss-lint:disable all
@import 'global/variables';

body {
    background: $sepia;
}

.login {
    h1 a,
    .wp-login-logo a  {
        background-color: transparent;
        background-image: url('../images/logo/origo.svg');
        background-position: center center;
        background-size: contain;
        height: 40px;
        width: 240px;
    }

    form {
        border-radius: 0.25rem;
        border-color: transparent;
        color: $white;
        position: relative;
        background-color: rgba($black, 0.3);
    }

    #nav,
    #backtoblog,
    .privacy-policy-page-link {
        a {
            color: $white;
        }
    }
}
